import * as React from "react"
import {graphql, Link, useStaticQuery} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import BlogPostPreviewList from 'contentcurator-gatsby-template/src/components/blog-post-preview-list'
import SearchBar from 'contentcurator-gatsby-template/src/components/search-bar'

import Layout from 'contentcurator-gatsby-template/src/containers/layout'
import Container from 'contentcurator-gatsby-template/src/components/container'

import * as styles_preview from 'contentcurator-gatsby-template/src/components/blog-post-preview-grid.module.css'

import * as styles from './index.module.css'
import {getPageUrl} from 'contentcurator-gatsby-template/src/lib/helpers'

import MetaSchemaSEO from 'contentcurator-gatsby-template/src/components/seo';


export const query = graphql`
  query {
    indexQuery: strapi{ siteSetting {
      id
      site_name
      default_seo_description
      hero_page {
        id
        title
        slug
        primary_topic
        page_type {
          name
          slug_prefix
        }
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        site_section: page_type{
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 1000,
                aspectRatio: 1.8,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
      featured_pages {
        id
        title
        slug
        primary_topic
        page_type {
          name
          slug_prefix
        }
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        site_section: page_type{
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
    }}
    strapi {
      pages(
        sort: "published_at:desc"
        limit: 1
        publicationState: LIVE
        where: { is_featured: "true" }
      ) {
        id
        is_featured
        slug
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        title_short
        primary_topic
        published_at
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        page_value
        main_image {
          url
          alternativeText
          caption
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 1000,
                aspectRatio: 2,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
    featuredTopPages: strapi {
      pages(
        sort: "published_at:desc"
        limit: 4
        publicationState: LIVE
        where: { is_featured: "true" }
        start: 1
      ) {
        id
        is_featured
        slug
        primary_topic
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        title_short
        primary_topic
        published_at
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        page_value
        main_image{
          url
          alternativeText
          caption
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 300,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
    newsPages2: strapi{ pages(
      sort: "created_at:desc"
      limit: 16
      start: 5
      publicationState: LIVE
      ) {
          id
          slug
          primary_topic
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          excerpt
          published_at
          parent {
            title
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
          main_image{
            url
            alternativeText
            caption
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 400,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY}
                )
              }
            }
          }
        }
      },
    newsPages: strapi{ pages(
      sort: "created_at:desc"
      limit: 10
      publicationState: LIVE
      ) {
          id
          slug
          primary_topic
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          authors {
            id
            name
            slug
            main_image {
              url
              caption
              alternativeText
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    quality: 80,
                    width: 50,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY})
                }
              }
            }
          }
          excerpt
          published_at
          parent {
            title
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
          main_image{
            url
            alternativeText
            caption
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 400,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY}
                )
              }
            }
          }
        }
      },
      relatedPages: strapi{ pages(
        sort: "created_at:desc"
        limit: 4
        publicationState: LIVE
        where: { is_featured: "true" }
        start: 5
        ) {
            id
            slug
            page_type {
              name
              slug_prefix
            }
            site_section: page_type{
              name
              slug_prefix
            }
            title
            excerpt
            published_at
            parent {
              title
              title_short
              slug
              page_type {
                name
                slug_prefix
              }
            }
            main_image{
              url
              alternativeText
              caption
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 300,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY}
                  )
                }
              }
            }
          }
        }
  }
`

// markup
const IndexPage = () => {
  const data = useStaticQuery(query);
  const heroPage = data.indexQuery.siteSetting.hero_page
  const featuredPages = data.featuredTopPages.pages
  const postNodes = data.newsPages.pages
  const featuredSitePages = data.indexQuery.siteSetting.featured_pages

  const videowrapper = {
      position: 'relative',
      overflow: 'hidden'
  };
  const fullScreenDiv = {
    padding: '0',
    margin: '0',
    position: 'relative',
    maxHeight: '700px'
  }
  return (
    <Layout>
      <MetaSchemaSEO
      />
  	
    <div style={videowrapper}>
        <div className={styles.fullScreenDiv} style={fullScreenDiv}>
            <video className={styles.videoStyle} role="presentation" preload="auto" playsinline="" crossorigin="anonymous" loop="true" muted="true" autoplay="true" class="blur">
                <source src="video/video.mp4" type="video/mp4"/>
            </video>
            <div className={styles.videoMessage}> 
                <div class="overlay overlay-2"></div>      
                <div class="container">
                    <div class="row justify-content-md-center">
                      <div class="col-md-10">
                          <div className={styles.title}>
                            <h1 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="0.3s">Accelerate<br/>Innovation</h1>
                            <p class="lead  wow fadeIn" data-wow-duration="1000ms" data-wow-delay="400ms">
                                Advanced analytics to accelerate digital strategy</p>
                          </div>
                      </div>
                    </div>
                </div>
            </div>   
        </div>
    </div>
     


      <Container>
          <h2 className={styles.h2}>Core Capabilities</h2>
        { featuredSitePages && (
          <div className={styles.featuredPages}>
            <ul className={styles_preview.grid_two}>
              <li>
                <BlogPostPreviewList
                  nodes={featuredSitePages.slice(0,1)}
                />
              </li>
              <li>

                <BlogPostPreviewList
                  nodes={featuredSitePages.slice(1,2)}
                />
              </li>
            </ul>
          </div>
        )
        }
          <div className={styles.hero}>
            {heroPage &&
                <Link to={getPageUrl(heroPage)} key={heroPage.id}>

                  {  heroPage.primary_topic &&
                    <span className={ styles.category }>{heroPage.primary_topic}</span>
                  }
                  <h2 className={styles.heroTitle}>{heroPage.title}</h2>
                  { heroPage.main_image && 
                  <GatsbyImage 
                    className={styles.heroImage} 
                    image={getImage(heroPage.main_image.imageFile)} 
                    alt={heroPage.title} />
                  }
                </Link>
              }
          </div>
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
        </Container>



      {featuredPages && featuredPages.length > 0 && (
        <Container>
          <h2 className={styles.h2}>Featured Articles</h2>
            <>
              <BlogPostPreviewList
                nodes={featuredPages}
              />
            </>
      </Container>
      )}


        <Container>
          <h2 className={styles.h2}>Latest Insights</h2>
          {postNodes && 
            <BlogPostPreviewList
              nodes={postNodes}
              skip_missing_images = {true}
            />
          }
        </Container>
    </Layout>
  )
}

export default IndexPage
